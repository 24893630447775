import { useEffect, useState } from "react";

import { useProfile } from "../providers/profile";

import Layout from "../components/layouts/dashboard";
import Intro from "../components/dashboard/intro";
import Summary from "../components/dashboard/summary";

const Page = () => {
  const [showIntro, setShowIntro] = useState(false);
  const { account } = useProfile();

  useEffect(() => {
    if (!account) return;
    const isTeacher = account.roles?.some((r) =>
      ["teacher", "admin", "superadmin"].includes(r as string)
    );
    if (isTeacher) return;
    if (!account.isActive) {
      setShowIntro(true);
    }
  }, [account]);

  return (
    <div className="h-full overflow-y-auto">
      {showIntro ? (
        <Intro setShowIntro={setShowIntro}></Intro>
      ) : (
        <Summary setShowIntro={setShowIntro}></Summary>
      )}
    </div>
  );
};

Page.getLayout = function getLayout(page: any) {
  return <Layout title="Dashboard">{page}</Layout>;
};

export default Page;
