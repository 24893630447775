import { Dispatch, SetStateAction } from "react";
import { useProfile } from "../../providers/profile";

import VideoJS from "../media/video-js";

const Intro = ({
  setShowIntro,
}: {
  setShowIntro: Dispatch<SetStateAction<boolean>>;
}) => {
  const { account, updateAccount, activeProfile } = useProfile();

  const activateAccount = async () => {
    if (!account) return;
    await updateAccount({
      id: account.id,
      isActive: true,
    });
    setShowIntro(false);
  };

  const folder = () => {
    switch (activeProfile?.instrument) {
      case "drums":
        return "drums-intro";
      case "guitar":
      case "bass":
        return "guitar-bass-intro";
      case "piano":
        return "piano-intro";
      case "voice":
        return "voice-intro";
      case "trumpet":
      case "saxophone":
      case "ukulele":
      case "clarinet":
      case "flute":
      case "violin":
      default:
        return "brass-wood-uke-intro";
    }
  };

  const type = () => {
    switch (activeProfile?.location) {
      case "manhattan":
      case "oakland":
      case "queens":
        return "in-person";
      case "virtual":
      default:
        return "virtual";
    }
  };

  return (
    <div className="px-4 py-16 text-center">
      <h2 className="mb-4 text-2xl">Introduction</h2>
      <p className="mb-16 text-sm">Welcome to your Real Brave account!</p>
      <div className="mb-12">
        <div className="mx-auto max-w-[800px] bg-gray-300 p-2 rounded-lg">
          <div className="aspect-video w-full bg-black rounded-lg">
            <VideoJS
              options={{
                autoplay: false,
                controls: true,
                responsive: true,
                fluid: true,
                preload: "auto",
                sources: [
                  {
                    src: `https://realbrave-public.s3.amazonaws.com/studio/video/${folder()}-${type()}/h264_master.m3u8`,
                    type: "application/x-mpegURL",
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
      <p>
        <button
          onClick={() => activateAccount()}
          className="mt-8 mb-32 inline-flex items-center justify-center bg-rlb-orange px-8 py-3 text-white uppercase tracking-wider hover:underline cursor-pointer"
        >
          {`I'M READY`}
        </button>
      </p>
    </div>
  );
};

export default Intro;
