import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import Image from "next/image";

import Logo from "../icons/logo";

import { useAuth0 } from "@auth0/auth0-react";
import { useProfile } from "../../providers/profile";
import useSWR from "swr";

import { idFetcher } from "../../utilities/fetcher";
import { instrumentsLight } from "../../types/instruments";
import { Appointment } from "../../types/acuity";

import greenWaves from "../../media/textures/green-waves.png";

export default function Summary({
  setShowIntro,
}: {
  setShowIntro: Dispatch<SetStateAction<boolean>>;
}) {
  const [thisMorning, setThisMorning] = useState<Date>();
  const [aMonthFromNow, setAMonthFromNow] = useState<Date>();

  const { getIdTokenClaims } = useAuth0();
  const { activeProfile, viewProfile } = useProfile();

  const { data: appointments } = useSWR<Appointment[]>(
    viewProfile?.account && thisMorning && aMonthFromNow
      ? `/api/acuity/appointments/list/${encodeURIComponent(
          viewProfile.account.email as string
        )}?minDate=${thisMorning.toISOString().split("T")[0]}&maxDate=${
          aMonthFromNow.toISOString().split("T")[0]
        }`
      : null,
    (url) => idFetcher(url, getIdTokenClaims),
    {
      refreshInterval: 5000,
    }
  );

  useEffect(() => {
    const thisMorning = new Date();
    thisMorning.setHours(0, 0, 0, 0);
    setThisMorning(thisMorning);

    const aMonthFromNow = new Date();
    aMonthFromNow.setMonth(aMonthFromNow.getMonth() + 1);
    setAMonthFromNow(aMonthFromNow);
  }, []);

  const instrumentComponent = useMemo(() => {
    if (!activeProfile) {
      return <></>;
    }
    const found = instrumentsLight.find(
      (i) =>
        i.name.toLocaleLowerCase() ===
        activeProfile.instrument?.toLocaleLowerCase()
    );
    if (!found) {
      return <></>;
    }
    return found.image;
  }, [activeProfile]);

  return (
    <div className="px-4 md:px-8 py-8 text-sm">
      <div className="sm:hidden h-8"></div>
      <div className="flex mb-16">
        <div className="mr-16">
          {activeProfile ? (
            <h2 className="mb-2 text-2xl">Hello, {activeProfile.name}! 👋</h2>
          ) : null}
          <p>Welcome to your very own PracticePad dashboard!</p>
          <p>{`We'll put helpful hints here.`}</p>
        </div>
        <div className="ml-auto text-right">
          <button
            onClick={() => setShowIntro(true)}
            className="mr-4 mb-4 align-middle border border-rlb-orange inline-flex items-center justify-center px-6 py-2 uppercase tracking-wider hover:underline cursor-pointer"
          >
            REWATCH INTRO
          </button>
          <div className="mr-4 mb-4 align-middle inline-block">
            <Logo height={20}></Logo>
          </div>
          <div className="mr-4 mb-4 align-middle inline-block">
            PracticePad&reg;
          </div>
        </div>
      </div>
      <div className="grid gap-8 lg:grid-cols-2">
        <div className="bg-white px-4 py-2 md:px-8 md:py-4 rounded-lg">
          <h3 className="mb-2 text-lg">Upcoming Schedule</h3>
          <div>
            {appointments ? (
              <>
                {appointments.length === 0 ? (
                  <p>No upcoming appointments.</p>
                ) : null}
                {appointments
                  .filter(
                    (a: Appointment) =>
                      !a.canceled &&
                      a.firstName?.trim() === viewProfile?.name?.trim()
                  )
                  .sort(
                    (a: Appointment, b: Appointment) =>
                      new Date(a.datetime).valueOf() -
                      new Date(b.datetime).valueOf()
                  )
                  .map((a: Appointment, i: number) => (
                    <p key={i}>
                      {a.calendar}:{" "}
                      {new Date(a.datetime)
                        .toLocaleString()
                        .replace(":00 ", " ")}
                    </p>
                  ))}
              </>
            ) : (
              <p className="text-center">Loading...</p>
            )}
          </div>
        </div>
        <div className="bg-white px-4 py-2 md:px-8 md:py-4 rounded-lg">
          <div className="grid gap-px bg-gray-300 lg:grid-cols-2">
            <div className="bg-white lg:pr-8 pb-8 lg:pb-0">
              <h3 className="mb-2 text-lg">Your instrument</h3>
              <div className="flex justify-center">{instrumentComponent}</div>
            </div>
            <div className="bg-white lg:pl-8 pt-8 lg:pt-0">
              <h3 className="mb-2 text-lg">History</h3>
              <p>⭐ Coming soon!</p>
            </div>
          </div>
        </div>
        <div className="lg:col-span-2 bg-white px-4 py-2 md:px-8 md:py-4 rounded-lg">
          <h3 className="mb-4 text-lg">Your Goals</h3>
          <div className="grid gap-8 lg:grid-cols-4">
            <div className="relative isolate flex-1 lg:aspect-video rounded-lg p-8">
              <div className="absolute -z-10 top-0 left-0 w-full h-full bg-rlb-orange rounded-lg opacity-40 overflow-hidden">
                <Image
                  alt=""
                  height={960}
                  width={820}
                  className="absolute -top-[120%] left-1/2 -translate-x-1/2 opacity-10 max-w-none"
                  src={greenWaves}
                />
              </div>
              <div className="text-4xl mb-4"></div>
              <p>Coming Soon!</p>
            </div>
            <div className="relative isolate flex-1 lg:aspect-video rounded-lg p-8">
              <div className="absolute -z-10 top-0 left-0 w-full h-full bg-rlb-yellow rounded-lg opacity-40 overflow-hidden">
                <Image
                  alt=""
                  height={960}
                  width={820}
                  className="absolute -top-[150%] left-1/2 -translate-x-1/2 opacity-10 max-w-none"
                  src={greenWaves}
                />
              </div>
              <div className="text-4xl mb-4"></div>
              <p></p>
            </div>
            <div className="relative isolate flex-1 lg:aspect-video rounded-lg p-8">
              <div className="absolute -z-10 top-0 left-0 w-full h-full bg-rlb-green rounded-lg opacity-40 overflow-hidden">
                <Image
                  alt=""
                  height={960}
                  width={820}
                  className="absolute -top-[100%] left-1/2 -translate-x-1/2 opacity-10 max-w-none"
                  src={greenWaves}
                />
              </div>
              <div className="text-4xl mb-4"></div>
              <p></p>
            </div>
            <div className="relative isolate flex-1 lg:aspect-video rounded-lg p-8">
              <div className="absolute -z-10 top-0 left-0 w-full h-full bg-black rounded-lg opacity-40 overflow-hidden">
                <Image
                  alt=""
                  height={960}
                  width={820}
                  className="absolute -top-[80%] left-1/2 -translate-x-1/2 opacity-10 max-w-none"
                  src={greenWaves}
                />
              </div>
              <div className="text-4xl">+</div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="bg-rlb-yellow px-4 py-2 md:px-8 md:py-4 rounded-lg">
          <h3 className="mb-2 text-xl">💎 {`We're remodeling!`}</h3>
          <p>This is a preview of the new dashboard layout!</p>
        </div>
      </div>
    </div>
  );
}
