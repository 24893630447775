import Bass from "../media/svg-instruments/bass.svg";
import Brass from "../media/svg-instruments/trumpet.svg";
import Violin from "../media/svg-instruments/violin.svg";
import Drums from "../media/svg-instruments/drums.svg";
import Guitar from "../media/svg-instruments/guitar.svg";
import Piano from "../media/svg-instruments/piano.svg";
import Sax from "../media/svg-instruments/sax.svg";
import Ukulele from "../media/svg-instruments/ukulele.svg";
import Voice from "../media/svg-instruments/mic.svg";

export const instruments = [
  {
    name: "Guitar",
    image: (
      <Guitar width="auto" height="170px" style={{ "--primary": "white" }} />
    ),
  },
  {
    name: "Piano",
    image: (
      <Piano
        width="115px"
        height="170px"
        style={{ "--primary": "var(--rlb-green)", "--secondary": "white" }}
      />
    ),
  },
  {
    name: "Drums",
    image: (
      <Drums
        width="auto"
        height="170px"
        style={{ "--primary": "var(--rlb-green)", "--secondary": "white" }}
      />
    ),
  },
  {
    name: "Bass",
    image: (
      <Bass width="auto" height="170px" style={{ "--primary": "white" }} />
    ),
  },
  {
    name: "Voice",
    image: (
      <Voice
        width="auto"
        height="160px"
        style={{ "--primary": "var(--rlb-green)", "--secondary": "white" }}
      />
    ),
  },
  {
    name: "Ukulele",
    image: (
      <Ukulele width="auto" height="170px" style={{ "--primary": "white" }} />
    ),
  },
  {
    name: "Violin",
    image: (
      <Violin width="auto" height="170px" style={{ "--primary": "white" }} />
    ),
  },
  {
    name: "Brass",
    image: (
      <Brass
        width="auto"
        height="170px"
        style={{ "--primary": "white", "--secondary": "var(--rlb-green)" }}
      />
    ),
  },
  {
    name: "Woodwind",
    image: (
      <Sax
        width="auto"
        height="170px"
        style={{ "--primary": "var(--rlb-green)", "--secondary": "white" }}
      />
    ),
  },
];

export const instrumentsLight = [
  {
    name: "Guitar",
    image: (
      <Guitar width="auto" height="170px" style={{ "--primary": "black" }} />
    ),
    bigImage: (
      <Guitar width="auto" height="400px" style={{ "--primary": "black" }} />
    ),
  },
  {
    name: "Piano",
    image: (
      <Piano
        width="115px"
        height="170px"
        style={{ "--primary": "black", "--secondary": "white" }}
      />
    ),
    bigImage: (
      <Piano
        width="225px"
        height="400px"
        style={{ "--primary": "black", "--secondary": "white" }}
      />
    ),
  },
  {
    name: "Drums",
    image: (
      <Drums
        width="auto"
        height="170px"
        style={{ "--primary": "black", "--secondary": "white" }}
      />
    ),
    bigImage: (
      <Drums
        width="auto"
        height="400px"
        style={{ "--primary": "black", "--secondary": "white" }}
      />
    ),
  },
  {
    name: "Bass",
    image: (
      <Bass width="auto" height="170px" style={{ "--primary": "black" }} />
    ),
    bigImage: (
      <Bass width="auto" height="400px" style={{ "--primary": "black" }} />
    ),
  },
  {
    name: "Voice",
    image: (
      <Voice
        width="auto"
        height="160px"
        style={{ "--primary": "black", "--secondary": "white" }}
      />
    ),
    bigImage: (
      <Voice
        width="auto"
        height="400px"
        style={{ "--primary": "black", "--secondary": "white" }}
      />
    ),
  },
  {
    name: "Ukulele",
    image: (
      <Ukulele width="auto" height="170px" style={{ "--primary": "black" }} />
    ),
    bigImage: (
      <Ukulele width="auto" height="400px" style={{ "--primary": "black" }} />
    ),
  },
  {
    name: "Violin",
    image: (
      <Violin width="auto" height="170px" style={{ "--primary": "black" }} />
    ),
    bigImage: (
      <Violin width="auto" height="400px" style={{ "--primary": "black" }} />
    ),
  },
  {
    name: "Brass",
    image: (
      <Brass
        width="auto"
        height="170px"
        style={{ "--primary": "black", "--secondary": "white" }}
      />
    ),
    bigImage: (
      <Brass
        width="auto"
        height="400px"
        style={{ "--primary": "black", "--secondary": "white" }}
      />
    ),
  },
  {
    name: "Woodwind",
    image: (
      <Sax
        width="auto"
        height="170px"
        style={{ "--primary": "black", "--secondary": "white" }}
      />
    ),
    bigImage: (
      <Sax
        width="auto"
        height="400px"
        style={{ "--primary": "black", "--secondary": "white" }}
      />
    ),
  },
];
